import styled, { css } from "styled-components"


export const CarouselImg = styled.div`
  ${(props) => {
   if(props.offerId)
   {
    return css`
    background-image: url(${({src}) => src || ""});
    background-repeat: no-repeat;
    background-position: ${({bgPosition}) => bgPosition || "center"};
    background-size: cover;
    min-height: ${({height}) => height || "65vh"};
    display: flex;
    flex-direction: column;
    justify-content:  center;
    transition:all ease .2s;
    @media   (max-width: 600px) {
        min-height: 70vh;
        background-image: url(${({src}) => src.replace("desktop", "mobile") || ""});
        align-items: flex-end;
    }
    @media only screen 
    and (device-width : 375px) 
    and (device-height: 667px) { 
        min-height: ${props.offerId === 268867 ? "81vh": "70vh" };
        background-image: url(${({src}) => src.replace("desktop", "mobile") || ""});
        align-items: flex-end; 
    }
    @media only screen 
    and (device-width : 540px) 
    and (device-height: 720px) { 
        min-height: ${props.offerId === 268867 ? "100vh": "70vh" };
        background-image: url(${({src}) => src.replace("desktop", "mobile") || ""});
        align-items: flex-end; 
    }
    @media only screen 
    and (device-width : 1024px) 
    and (device-height: 600px) { 
        min-height: ${props.offerId === 268867 ? "236vh": "70vh" };
        background-image: url(${({src}) => src.replace("desktop", "mobile") || ""});
        align-items: flex-end; 
        background-position: center;
        background-size: cover;
    }
    @media only screen 
    and (device-width : 1280px) 
    and (device-height: 800px) { 
        min-height: ${props.offerId === 268867 ? "236vh": "70vh" };
        background-image: url(${({src}) => src.replace("desktop", "mobile") || ""});
        align-items: flex-end; 
        background-position: center;
        background-size: cover;
    }
    
    
  `
   }
  }}
  `

export const CarouselContent = styled.div`
    color: #fff;
    text-align: center;
`

export const DotIndicatorWrapper = styled.div` 
    width: 100%;
`
export const DotIndicator = styled.div`
    &:hover{
        opacity: 1;
        background-color: #e20074;
    }
    height: 10px;
    width: 10px;
    height: ${({active}) => active ? "10px" : "9px"};
    width: ${({active}) => active ? "10px" : "9px"};
    background-color: #e20074;
    border-radius: 50%;
    display: inline-block;
    border: solid 1px #e20074;
    background-color: ${({active}) => active ? "#e20074" : "#fff"};
    cursor:pointer;
    transition:all ease .2s;
`


